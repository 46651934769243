import React from "react"
import Layout from "../../components/layout/layout"

export default class Contact extends React.Component {
  constructor(props) {
    super(props)
  }
  render() {
    return (
      <Layout>
        <div className="">
          <h3>Contact</h3>
          <strong>Healthcare Management Partners, LLC</strong>
          <p>
            The most efficient way to contact the Receiver is to email the
            Receiver directly at directly at
            <a href="mailto:oxton.receivership@hcmpllc.com">
              {" "}
              oxton.receivership@hcmpllc.com
            </a>
          </p>
          <strong>Phone:</strong> (615) 601-2109
          <br />
          <br />
          <strong>Mailing Address:</strong>
          <p>
            Healthcare Management Partners, LLC <br />
            1033 Demonbreun Street, Unit 300
            <br />
            Nashville, TN 37203
          </p>
          <strong>The Receiver's counsel can be reached by email at:</strong>
          <p>
            <a href="mailto:Ryan.Cochran@wallerlaw.com">
              Ryan.Cochran@wallerlaw.com
              <br />
            </a>
            <a href="mailto:Blake.Roth@wallerlaw.com">
              Blake.Roth@wallerlaw.com
            </a>
          </p>
        </div>
      </Layout>
    )
  }
}
